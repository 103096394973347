<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
            v-if="!mealId"
            label="Lookup by ID"
            v-model="lookupById"
            @keyup.enter="$router.push({ params: {id : lookupById}})"
        />
        <MealDetail
            v-if="mealId"
            :mealId="mealId"
            :date="date"
            :read-only="true"
            :can-edit-recipe="!readOnly"
            :show-prep-controls="true"
            :show-nutrient-controls="false"
            :show-meal-cost="false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MealDetail from '@/components/MealDetail';

export default {
  mounted() {

  },
  data() {
    return {
      dialog: true,
      lookupById: null
    }
  },
  components: {
    MealDetail,
  },
  created() {
  },
  computed: {
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    mealId() {
      return this.$route.params.id;
    },
    date() {
      return this.$route.params.date;
    },
  },
  methods: {}
}
</script>
